.card-blog {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin: 12px 0;
  height: calc(100% - 24px);
  .blog-btn-wrapper {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    .blog-img {
      height: 140px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    button {
      background: #c60e7b;
      font-weight: 600;
      font-size: 35px;
      line-height: 52px;
      letter-spacing: 0.3em;
      padding: 24px;
      color: #edecec;
      position: absolute;
      border: none;
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
      &:hover {
        cursor: default;
        // background: #faa624;
        // color: #242122;
      }
    }
  }
  h5 {
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-sub{
    font-style: italic;
  }
  .text-description {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    color: #47484a;
    min-height: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // min-height: 50px;
  }
  .btn-wrap {
    margin-top: 8px;
    border-top: 1px solid rgba(255, 195, 75, 0.37);
    padding: 25px 0 0;
    .btn-learn {
      background: #faa624;
      border: 3px solid rgba(255, 255, 255, 0.17);
      border-radius: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #242122;
      padding: 5px 24px;
      text-decoration: none;
    }
  }
  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #c60e7b;
      margin: 0;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #71788a;
    }
  }
}
