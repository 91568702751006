// @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.catchup-subwrap {
  padding: 0px 0px 50px 0px;
  position: relative;

  .catch-title {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 59px;
    padding-bottom: 50px;
    margin-bottom: 0;
    color: #fdf3f3;
    @media screen and(max-width:767px) {
      font-size: 28px;
      line-height: 40px;
    }
    @media screen and(max-width:576px) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  @media screen and(max-width:767px) {
    padding: 50px 16px;
  }
  .featured-card {
    background: #f7d082;
    border-radius: 6px;
    padding: 20px;
    margin: 12px 0;
    height: calc(100% - 24px);
    h5 {
      border-bottom: 1px solid #c60e7b;
    }
    .featured-info {
      gap: 21px;
      margin: 20px 0;
      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .date-wrap {
        font-size: 16px;
        line-height: 24px;
        color: #71788a;
      }
      .name-wrap {
        font-size: 16px;
        line-height: 24px;
        color: #c60e7b;
      }
    }
    .poly-2 {
      bottom: -70px;
      right: -70px;
      @media screen and(max-width:767px) {
        display: none;
      }
    }
  }
  .poly-img {
    width: fit-content;
  }
  .poly-1 {
    top: 50%;
    left: 0;
    @media screen and(max-width:767px) {
      display: none;
    }
  }

  .poly-3 {
    top: 10%;
    right: 0;
    @media screen and(max-width:767px) {
      display: none;
    }
  }
}
.pagination {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .page-item {
    &.active {
      .page-link {
        background-color: #c60e7b;
        border: 1px solid #c60e7b;
        color: #fff;
      }
    }
    .page-link {
      color: #c60e7b;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      background: rgba(142, 142, 142, 0.22);
      border: 0;
    }
  }
}
