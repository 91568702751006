.menu {
  background-image: url(../../Images/newsBackground.png);
  background-size: cover;
  height: 521px;
  .title {
    height: calc(100% - 100px);
    @media screen and (max-width: 991px) {
      height: calc(100% - 0px);
    }
    h3 {
      font-weight: 500;
      font-size: 100px;
      line-height: 148px;
      @media screen and (max-width: 576px) {
        font-size: 78px;
      }
    }
  }
}