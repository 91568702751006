.news-main-wrapper {
  // background-color: #f7f7f7;
  // padding: 50px 30px;
  margin: 50px 0px;
  .form-container {
    max-width: 62%;
    width: 100%;
    margin: 0 auto;
  }
}
