.connect-main-wrap {
  background-color: #242122;
}

.get-touch-form {
  max-width: 875px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 576px) {
    padding: 0px 16px;
  }
  .title-contact {
    .title {
      font-weight: 700;
      font-size: 40px;
      line-height: normal;
      text-align: center;
      color: #fba627;
      @media screen and(max-width:576px) {
        font-size: 32px;
        line-height: 37px;
      }
    }
    h5 {
      font-size: 30px;
      line-height: normal;
      text-align: center;
      color: #eee3d2;
      width: 70%;
      margin: 0 auto 50px;
      font-weight: 400;
      @media screen and(max-width:991px) {
        font-size: 22px;
        line-height: 37px;
      }
      @media screen and(max-width:576px) {
        font-size: 15px;
        line-height: 20px;
      }
      @media screen and(max-width:375px) {
        font-size: 15px;
        line-height: 27px;
      }
    }
  }
  .form-page-wrap {
    .form-group-box {
      .form-label {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #fba627;
        padding: 20px 0px 10px 0px;
        margin: 0;
      }
      input {
        &::placeholder {
          color: #a3a1a1;
        }
      }
      .form-control {
        height: 60px;
        background: #ffffff;
        border: 1px solid #fba627;
        border-radius: 10px;
        color: #000;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .form-group-box1 {
      .form-label {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #fba627;
        padding: 20px 0px 10px 0px;
        margin: 0;
      }
      input {
        &::placeholder {
          color: #a3a1a1;
        }
      }
      .form-control {
        background: #ffffff;
        border: 1px solid #fba627;
        border-radius: 10px;
        color: #000;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .button-submit {
      padding-bottom: 30px;
      button {
        border: none;
        width: 585px;
        height: 60px;
        background: linear-gradient(103.13deg, #faa624 4.09%, #c91776 55.22%);
        border-radius: 10px;
        font-weight: 700;
        font-size: 22px;
        line-height: 45px;
        text-transform: uppercase;
        color: #ffffff;
        &:hover {
          background: linear-gradient(
            103.13deg,
            hsl(38, 100%, 52%) 4.09%,
            #f7178e 55.22%
          );
          transition: 0.5s;
          cursor: pointer;
        }
      }
    }
  }
}
