.content-wrapper {
  .img-wrap {
    position: relative;

    @media screen and(max-width:576px) {
      padding: 16px 0px;
    }
    img {
      position: absolute;
      top: -224px;
      z-index: 1;
      object-fit: contain;
      height: auto;
      border-radius: 50px;
      max-width: 100%;
      width: 100%;
      background-color: #fff;

      height: 570px;
      @media screen and(max-width:1024px) {
        top: -226px;
      }
      @media screen and(max-width:576px) {
        top: -100px;
        padding: 0px 10px;
      }
    }
    // &::after{
    //   background-image: url(../../Images/blogpolygon1.png);
    //   width: 100px;
    //   height: 100px;
    //   top: 0;
    //   bottom: 0;
    //   position: absolute;

    // }
  }
  .catch-box {
    padding: 360px 0px 0px 0px;
    @media screen and (max-width: 1024px) {
      padding: 343px 0px 0px 0px;
    }
    @media screen and (max-width: 991px) {
      padding: 343px 0px 0px 0px;
    }
    @media screen and (max-width: 576px) {
      padding: 420px 16px 0px 16px;
    }
    // @media screen and (max-width: 767px) {
    //   padding: 50px 16px 0px 16px;
    // }
    .catch-border {
      padding: 40px 0px 30px 0px;
      border-bottom: 1px solid #fccd6f;
    }
    .catch-title {
      font-weight: 500;
      font-size: 60px;
      line-height: 89px;
      color: #fba627;
      border-left: 3px solid #c60e7b;
      padding-left: 10px;
      @media screen and (max-width: 991px) {
        font-size: 48px;
        line-height: 62px;
      }
      @media screen and (max-width: 576px) {
        font-size: 34px;
        line-height: 40px;
        margin-top: 0px;
      }
    }

    .catch-subtitle {
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 5px;
      }
    }
    .catch-details {
      font-weight: 400;
      font-size: 24px;
      line-height: 44px;
      letter-spacing: 0.03em;
      color: #a3a1a1;
      width: 76%;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
        line-height: 30px;
      }
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 22px;
        width: 100%;
      }
      @media screen and (max-width: 576px) {
        font-size: 14px;
        line-height: 22px;
        width: 100%;
      }
    }

    .text__discription {
      font-weight: 200;
      font-size: 25px;
      line-height: 37px;
      text-align: justify;
      color: #fdf3f3;
      @media screen and (max-width: 576px) {
        font-size: 16px;
        line-height: 30px;
      }
    }
    .comment-wrap {
      // padding: 50px 0;
      // border-bottom: 1px solid #fccd6f;
      flex-direction: column-reverse;
      column-gap: 20px;
      @media screen and (max-width: 1024px) {
        column-gap: 10px;
      }

      .profile-wrap {
        .owner-name {
          font-weight: 500;
          font-size: 25px;
          line-height: 37px;
          color: #ffffff;
          @media screen and (max-width: 1400px) {
            font-size: 18px;
            line-height: 28px;
          }
          @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .profile-img {
          img {
            width: 70px;
            height: 70px;
            @media screen and (max-width: 1400px) {
              width: 60px;
              height: 60px;
            }
            @media screen and (max-width: 576px) {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
      .date-wrap {
        padding: 10px 0px;
        font-weight: 500;
        font-size: 24px;
        line-height: 44px;
        letter-spacing: 0.03em;
        color: #6f6f6f;
        @media screen and (max-width: 1400px) {
          font-size: 18px;
          line-height: 28px;
        }
        @media screen and (max-width: 576px) {
          font-size: 14px;
          line-height: 22px;
        }
        span {
          color: #ffc34b;
        }
      }
    }
    .leave-title {
      font-weight: 500;
      font-size: 60px;
      line-height: 75px;
      color: #fba627;
      border-left: 3px solid #c60e7b;

      padding-left: 10px;
      margin-top: 50px;
      @media screen and (max-width: 991px) {
        font-size: 48px;
        line-height: 62px;
      }
      @media screen and (max-width: 576px) {
        font-size: 36px;
        line-height: 45px;
        margin-top: 30px;
      }
    }
    .input-wrap {
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      color: #fba627;
      margin-bottom: 64px;
      @media screen and (max-width: 576px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      input {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid transparent;
        border-radius: 10px;
        color: #ffffff;
        height: 68.21px;
        outline: 0;
        padding: 21px;
        @media screen and (max-width: 576px) {
          height: 48px;
        }
        &::placeholder {
          line-height: 1;
        }
        &:focus {
          border: 2px solid #fba627;
        }
      }
      textarea {
        background: rgba(255, 255, 255, 0.2);
        opacity: 0.8;
        border: 1px solid transparent;
        border-radius: 10px;
        height: 173.14px;
        outline: 0;
        color: #ffffff;
      }
    }
    .submit-btn {
      background: linear-gradient(103.13deg, #faa624 4.09%, #c91776 55.22%);
      border-radius: 10px;
      color: #ffffff;
      border: 0;
      max-width: 585px;
      width: 100%;
      height: 79px;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      text-transform: uppercase;
      color: #ffffff;
      @media screen and (max-width: 576px) {
        height: 52px;
        font-size: 22px;
        line-height: 28px;
        font-weight: 500;
      }
      &:hover {
        background: linear-gradient(103.13deg, #c91776 4.09%, #faa624 55.22%);
        transition: 0.8s;
      }
    }
  }
  .blog-polygon-pink {
    position: absolute;
    right: 0;
    top: 54px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .blog-polygon-orange {
    position: absolute;
    top: 32%;
    left: 0;

    @media screen and (max-width: 1200px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .blog-polygon-multicolor {
    position: absolute;
    top: 53%;
    right: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
