.navbar-collapse {
  .navbar-nav {
    display: flex;
    align-items: center;
    .nav-item {
      padding: 0 16px;

      cursor: pointer;
      &:last-child {
        padding: 0;
      }
      &:hover {
        .nav-link {
          position: relative;
          color: #fff;
          &:before {
            position: absolute;
            bottom: -8px;
            width: 50%;
            height: 2px;
            left: 50%;
            transform: translateX(-50%);
            background: #fba627;
            content: "";
          }
        }
      }
      .nav-link {
        padding: 0;
        position: relative;
        color: #fff;
        &.active {
          color: #fff;
          &:before {
            position: absolute;
            bottom: -8px;
            width: 50%;
            height: 2px;
            left: 50%;
            transform: translateX(-50%);
            background: #fba627;
            content: "";
          }
        }
      }
    }
  }
}
.leftContainer {
  display: flex;
  align-items: center;
  .logo {
    max-width: 45px;
    margin-right: 14px;
  }
  .logoText {
    color: #fff;
    font-weight: 500;
    font-size: 36px;
    line-height: 59px;
  }
}

.main-wrapper-navbar {
  background-color: #ff6767;
  .navbar {
    background: linear-gradient(109.93deg, #fba627 18.28%, #c60e7b 92.65%);
    // padding: 4px 16px;

    .navbar-nav {
      .nav-item {
        padding: 0 16px;
        a {
          color: #fff;
          font-size: 16px;
          text-decoration: none;
          // padding: 0px 10px;
          font-weight: 400;

          &:hover {
            color: #ffa43c;
          }
        }
        .logout-button {
          button {
            background-color: #fba627;
            border: none;
            color: #fff;
            font-size: 18px;
            padding: 7px 20px;
            margin: 0px 0px 0px 12px;
            &:hover {
              background-color: #fff;
              color: #fba627;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
}
