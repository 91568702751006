.form-name-wrapper {
  .form-box {
    background-color: #f9f9f9;
    padding: 30px 30px;
    box-shadow: 1px 3px 3px #e1e1e1;
    border-radius: 10px;

    .form-pages-box {
      .form-label {
        font-size: 16px;
        color: #201b1bbf;
      }
      input {
        font-size: 14px;
        height: 40px;
        &::placeholder {
          color: rgb(182, 179, 179);
        }
      }
    }

    .form-pages-box1 {
      .form-label {
        font-size: 16px;
        color: #201b1bbf;
      }
      .form-control {
        font-size: 14px;

        &::placeholder {
          color: rgb(182, 179, 179);
        }
      }
      .quill {
        .ql-container {
          min-height: 100px;
        }
      }
    }

    .form-pages-box2 {
      .form-label {
        font-size: 16px;
        color: #201b1bbf;
      }
      .form-control {
        font-size: 14px;
        color: rgb(182, 179, 179);

        &::placeholder {
          color: rgb(182, 179, 179);
        }
      }
    }
  }
  .title-error {
    font-size: 14px;
    color: #645757bf;
    padding: 14px 0px;
  }
  .button-save {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background-color: #fba627;
      border: none;
      color: #fff;
      font-size: 18px;
      padding: 7px 30px;
      border: 1px solid transparent;

      &:hover {
        background-color: #fff;
        color: #fb9834;
        transition: 0.5s;
        border: 1px solid #fb9834;
      }
    }
  }
}
