footer {
  background: url(../../Images/FooterBG.png) no-repeat center/cover;
  height: 640px;
  padding: 50px;
  @media screen and (max-width: 1024px) {
    height: 650px;
  }
  @media screen and (max-width: 991px) {
    height: 695px;
  }
  @media screen and (max-width: 767px) {
    height: 840px;
  }
  @media screen and (max-width: 576px) {
    height: 872px;
  }
  @media screen and (max-width: 420px) {
    height: 855px;
  }
  @media screen and (max-width: 376px) {
    height: 900px;
  }
  .footer-section {
    background: linear-gradient(
      138.59deg,
      rgba(255, 255, 255, 0.4) -0.95%,
      rgba(255, 255, 255, 0.1) 102.21%
    );
    padding: 50px;
    backdrop-filter: blur(30px);
    border-radius: 15px;
    height: 100%;
    @media screen and (max-width: 767px) {
      padding: 30px;
    }
    .news-letter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 375px) {
        padding: 0px;
      }
      .news-head {
        display: flex;
        flex-direction: column;
        padding-left: 16px;
        border-left: 3px solid #c60e7b;
        h4 {
          font-weight: 500;
          font-size: 40px;
          line-height: 59px;
          color: #c60e7b;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #333842;
          margin: 0;
        }
      }
      .email-wrap {
        position: relative;
        @media screen and (max-width: 991px) {
          margin: 32px 0px;
          width: 56%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin: 32px 0px;
        }
        input {
          background: #c60e7b;
          width: 320px;
          height: 48px;
          border-radius: 25px;
          border: none;
          padding-left: 20px;
          padding-right: 100px;
          font-family: "Oswald";
          font-size: 16px;
          color: #cbcbcb;
          &:focus,
          &:hover,
          &:focus-within {
            outline: none;
            box-shadow: none;
          }
          &::placeholder {
            color: #cbcbcb;
          }
          @media screen and (max-width: 991px) {
            width: 100%;
          }
        }
        .btn-sign {
          position: absolute;
          right: 13px;
          border-radius: 25px;
          top: 50%;
          transform: translateY(-50%);
          background: #ffc34b;
          border: 1px solid #ffffff;
          padding: 2px 10px;
          font-weight: 400;
          font-size: 16px;
          color: #000;
        }
      }
    }
    .footer-logo {
      width: 100%;
      padding: 26px;
      height: 100%;
      background: linear-gradient(180deg, #fba627 0%, #c60e7b 100%);
      border-radius: 17px;

      @media screen and (max-width: 767px) {
        max-width: 576px;
        margin: 0 auto;
      }

      @media screen and (max-width: 576px) {
        max-width: 320px;
        margin: 0 auto;
      }
      @media screen and (max-width: 375px) {
        max-width: 280px;
        margin: 0 auto;
      }
      .leftContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .logoText {
          font-weight: 600;
          font-size: 30px;
          line-height: 44px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
        }
        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            font-size: 13px;
            line-height: 18px;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
          }
          @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 22px;
          }
        }

        img {
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .link-menu {
      &.footer-menu {
        margin-left: 50px;

        @media screen and (max-width: 767px) {
          margin-left: 0px;
        }
      }
      h5 {
        font-weight: 600;
        font-size: 20px;
        padding-left: 8px;
        border-left: 2px solid #ffc34b;
        line-height: 30px;
        color: #c60e7b;
        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        margin-bottom: 3px;
        line-height: 27px;
        padding-left: 10px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        &.social {
          display: flex;
          li {
            padding-left: 0;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 14px;
            @media screen and (max-width: 991px) {
              width: 38px;
              height: 38px;
              min-width: 38px;
            }
            @media screen and (max-width: 767px) {
              width: 38px;
              height: 38px;
              min-width: 38px;
              margin-right: 8px;
            }
          }
          img {
            @media screen and (max-width: 767px) {
              width: 18px;
              height: 18px;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
        li {
          list-style: none;
          padding-left: 10px;
          a {
            text-decoration: none;
            font-weight: 400;
            font-size: 18px;
            color: #fff;
            margin-bottom: 3px;
            line-height: 27px;
            &.icon {
              margin-bottom: 0;
              display: flex;
            }
            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
            @media screen and (max-width: 576px) {
              font-size: 14px;
            }
          }
          &:hover {
            .nav-link {
              color: #cbcbcb;
            }
          }
        }
      }
    }
    .footer-copy {
      padding-top: 20px;
      margin-top: 30px;
      border-top: 1px solid #fff;
      width: 100%;
      @media screen and(max-width:375px) {
        padding-top: 9px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media screen and (max-width: 567px) {
          font-size: 14px;
        }
        @media screen and(max-width:375px) {
          font-size: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 20px 25px;
    .footer-section {
      .news-letter-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
