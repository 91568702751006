.aboutus-main-wrap {
  background-color: #242122;
}

.about-images-wrapper {
  padding: 85px 0px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    background-color: #0000004d;
    position: absolute;
    width: 100%;
    min-height: 414px;
    color: #fff;
    transform: skewY(9deg);
    z-index: -1;
    top: 25%;

    @media screen and (max-width: 767px) {
      min-height: 11%;
      top: 18%;
    }
  }

  .about-leftfirst-pink {
    position: absolute;
    top: 216px;
    left: 0;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .about-leftsecond-pink {
    top: 18%;
    right: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .about-leftthird-pink {
    top: 38%;
    left: 0;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .about-leftforth-pink {
    top: 80%;
    left: 0;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .about-leftfifth-pink {
    top: 61%;
    right: 0;
    transform: rotate(5deg);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .rounded-circle {
    width: 450px;
    height: 450px;
    flex-direction: column;
    background: linear-gradient(180deg, #fec34e 0%, #c60e7b 100%);
    transform: rotate(-0.01deg);

    @media screen and (max-width: 991px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 380px;
      width: 100%;
      height: 380px;

      img {
        width: 100px;
      }
    }
    @media screen and (max-width: 576px) {
      max-width: 300px;
      width: 100%;
      height: 300px;

      img {
        width: 100px;
      }
    }
    .alter-title {
      font-weight: 300;
      font-size: 52px;
      line-height: 95px;
      color: #ffffff;
      transform: rotate(-0.01deg);
      @media screen and (max-width: 576px) {
        font-size: 38px;
      }
    }
  }
  .about-paragarph-wrap {
    @media screen and (max-width: 576px) {
      padding: 0px 16px;
    }
    .for-section {
      margin: 20px 0px;
      @media screen and (max-width: 991px) {
        margin: 50px 0px;
      }
      h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.115em;
        color: #ffffff;
        margin-bottom: 10px;
        span {
          background: linear-gradient(180deg, #f28d34 0%, #d33465 100%);
          border-radius: 6px 0px 0px 6px;
          padding: 8px 10px 8px 30px;
        }
      }
      p {
        font-weight: 200;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
      }
      .title-altr {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 48px;
        color: #fba627;
        letter-spacing: 0;
        padding-top: 19px;
      }
      .paragraph-altr {
        font-style: normal;
        font-weight: 200;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        margin: 19px 0px 24px 0px;
      }
      .button-join {
        button {
          background-color: #fba627;
          padding: 10px 30px;
          border-radius: 10px;
          color: #242122;
          border: none;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
  .about-meetteam-warp {
    margin-top: 20px;
    @media screen and (max-width: 576px) {
      padding: 0px 16px;
    }
    .for-section {
      margin: 20px 0px;
      @media screen and (max-width: 991px) {
        margin: 50px 0px;
      }
      @media screen and (max-width: 767px) {
        margin: 30px 0px;
      }
      h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.115em;
        color: #ffffff;
        margin-bottom: 10px;
        span {
          background: linear-gradient(180deg, #f28d34 0%, #d33465 100%);
          border-radius: 6px 0px 0px 6px;
          padding: 8px 10px 8px 30px;
        }
      }
      p {
        font-weight: 200;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
      }
      .title-altr {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 48px;
        color: #fba627;
        letter-spacing: 0;
      }
      .paragraph-altr {
        font-style: normal;
        font-weight: 200;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        margin: 19px 0px 24px 0px;
      }
      .button-join {
        button {
          background-color: #fba627;
          padding: 10px 30px;
          border-radius: 10px;
          color: #242122;
          border: none;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
    .carley-name-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 59px;
      letter-spacing: 0.01em;
      color: #fba627;
      padding-top: 19px;
      margin: 0;
    }
    .ceo-founder {
      font-weight: 500;
      font-size: 40px;
      line-height: 59px;
      letter-spacing: 0.01em;
      color: #fff;
      margin: 0;
    }
    .paragarph {
      padding-top: 14px;
      font-style: normal;
      font-weight: 200;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .hexagonal-shape {
    margin-top: 50px;
    @media screen and (max-width: 991px) {
      margin-top: 0px;
    }
    @media screen and (max-width: 576px) {
      margin-top: 0px;
    }

    .main-wrap {
      margin: 0 auto;
      width: fit-content;
      .shap-wrap {
        background: url(../../Images/ceo-image.png) 300px no-repeat;
        padding: 10px;
        width: 425px;
        height: 500px;
        position: relative;
        &.ceo {
          background: url(../../Images/ceo-image.png) 300px no-repeat;
          background-size: cover;
          background-position: center;
        }
        &.cto {
          background: url(../../Images/cto.png) 300px no-repeat;
          background-size: cover;
          background-position: center;
        }
        &.partner {
          background: url(../../Images/partner.png) 300px no-repeat;
          background-size: cover;
          background-position: center;
        }
        @media screen and (max-width: 420px) {
          padding: 0px;
          margin-bottom: 20px;
          width: 355px;
          height: 420px;
        }
        .r-hex {
          overflow: hidden;
          display: inline-block;
          margin: 3em 0;
          width: 491px;
          height: 376px;
          transform: rotate(-30deg) skewX(30deg);
          border-radius: 40px;
          @media screen and (max-width: 576px) {
            width: 406px;
            height: 300px;
          }
          @media screen and (max-width: 420px) {
            width: 350px;
            height: 250px;
          }
        }
        .r-hex *,
        .r-hex *:before {
          display: block;
          overflow: hidden;
          width: inherit;
          height: inherit;
          border-radius: inherit;
          position: absolute;
        }
        .r-hex-inner {
          transform: skewX(-30deg) rotate(60deg) skewX(30deg);
          cursor: pointer;
        }
        .r-hex:first-child .r-hex-inner:before,
        .r-hex-inner-2 {
          transform: skewX(-30deg) rotate(60deg) skewX(30deg);
          // background: orange;
          content: "";
        }
        .r-hex-inner-2:before {
          z-index: 999999;
          height: 450px;
          transform: skewX(-30deg) rotate(-90deg);
          background: url(../../Images/ceo-image.png) 300px no-repeat;
          background-size: cover;
          background-position: center;
          content: "";
          top: -44px;
          width: 395px;
          left: 61px;
          @media screen and (max-width: 576px) {
            height: 367px;
            top: -46px;
            width: 330px;
            left: 58px;
          }
          @media screen and (max-width: 420px) {
            height: 309px;
            top: -30px;
            width: 253px;
            left: 58px;
          }
        }
      }
    }

    // .main-wrap {
    //   margin: 0 auto;
    //   width: fit-content;
    //   width: 93%;
    //   @media screen and (max-width: 1200px) {
    //     width: 71%;
    //   }
    //   @media screen and (max-width: 991px) {
    //     width: 65%;
    //   }

    //   @media screen and (max-width: 767px) {
    //     width: 87%;
    //   }

    //   @media screen and (max-width: 576px) {
    //     width: 63%;
    //   }
    //   .shap-wrap {
    //     background-image: url(../../Images/aboutus-poly.png);
    //     background-size: contain;
    //     padding: 10px;
    //     background-position: center;
    //     width: fit-content;
    //     position: relative;
    //     background-repeat: no-repeat;
    //     width: 100%;
    //     height: 525px;
    //     overflow: hidden;
    //     @media screen and(max-width:991px) {
    //       height: 530px;
    //     }
    //     .r-hex {
    //       overflow: hidden;
    //       display: inline-block;
    //       margin: 3em 0;../../Images/ceo-image.jpg
    //       @media screen and (max-width: 1440px) {
    //         width: 401px;
    //         height: 349px;
    //         left: 10.8%;
    //         top: 44.1px;
    //       }
    //       @media screen and (max-width: 1200px) {
    //         width: 275px;
    //         height: 341px;
    //         left: 7.5%;
    //         top: 83.9px;
    //         margin: 10px 0;
    //       }
    //       @media screen and (max-width: 991px) {
    //         width: 376px;
    //         height: 401px;
    //         left: 8.4%;
    //         top: 55.9px;
    //         margin: 11px 0;
    //         border-radius: 22px;
    //       }

    //       @media screen and (max-width: 767px) {
    //         width: 368px;
    //         height: 395px;
    //         left: 9.4%;
    //         top: 57.9px;
    //         margin: 11px 0;
    //         border-radius: 22px;
    //       }

    //       @media screen and (max-width: 576px) {
    //         width: 368px;
    //         height: 395px;
    //         left: 9.4%;
    //         top: 57.9px;
    //         margin: 11px 0;
    //         border-radius: 22px;
    //       }
    //     }
    //     .r-hex *,
    //     .r-hex *:before {
    //       display: block;
    //       overflow: hidden;
    //       width: inherit;
    //       height: inherit;
    //       border-radius: inherit;
    //       position: absolute;
    //     }
    //     .r-hex-inner {
    //       transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    //       cursor: pointer;
    //     }
    //     .r-hex:first-child .r-hex-inner:before,
    //     .r-hex-inner-2 {
    //       transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    //       background: orange;
    //       content: "";
    //     }
    //     .r-hex-inner-2:before {
    //       height: 407px;
    //       transform: skewX(-30deg) rotate(-90deg);
    //       background: url(../../Images/ceo-image.jpg) 300px no-repeat;
    //       background-size: cover;
    //       background-position: center;
    //       content: "";
    //       top: -42px;
    //       left: 11px;
    //       @media screen and (max-width: 1440px) {
    //         top: -36px;
    //         left: 11px;
    //       }
    //       @media screen and (max-width: 1200px) {
    //         top: -1px;
    //         left: 15px;
    //         height: 315px;
    //       }
    //       @media screen and (max-width: 991px) {
    //         top: -6px;
    //         left: 9px;
    //         height: 428px;
    //       }
    //       @media screen and (max-width: 767px) {
    //         top: -24px;
    //         left: 21px;
    //         height: 418px;

    //       }

    //       @media screen and (max-width: 576px) {
    //         top: -24px;
    //         left: 21px;
    //         height: 418px;

    //       }
    //     }
    //   }
    // }

    // .ceo-img-wrap {
    //   display: flex;
    //   justify-content: center;

    //   @media screen and (max-width: 576px) {
    //     padding: 0px 16px;
    //   }
    // }
    // @media screen and (max-width: 1199px) {
    //   margin: 0 auto;
    // }

    // @media screen and (max-width: 991px) {
    //   margin: 50px 0px;
    // }

    // .ceo-background {
    //   position: absolute;
    //   right: 9px;
    //   left: 26%;
    //   bottom: 0;
    //   width: 48%;
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;

    //   @media screen and (max-width: 1200px) {
    //     width: 54%;
    //   }

    //   @media screen and (max-width: 991px) {
    //     left: 30%;
    //     width: 42%;
    //   }

    //   @media screen and (max-width: 767px) {
    //     left: 23%;
    //     width: 53%;
    //   }
    // }
  }
  .aaron-title-wrap {
    margin-top: 10px;
    @media screen and (max-width: 576px) {
      padding: 0px 16px;
    }
    @media screen and (max-width: 991px) {
      margin: 30px 0px;
    }
    .aaron-name {
      font-style: normal;
      font-weight: 500;
      font-size: 44px;
      line-height: 50px;
      letter-spacing: 0.01em;
      color: #fba627;
    }
    .strategic-title {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 50px;
      letter-spacing: 0.01em;
      color: #ffffff;
      transform: rotate(-0.01deg);
    }
    .paragraph {
      font-weight: 200;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .triangl-shape-images {
    position: relative;
    .triangle-shape {
      position: relative;
    }
    .user-name {
      background-color: #f8f9fa;
      position: absolute;
      top: 10px;
      left: 123px;
      padding: 20px 35px 0px 35px;
      border-radius: 40px;

      @media screen and(max-width:1199px) {
        top: 13px;
        left: 84px;
        border-radius: 30px;
        width: 64%;
      }

      @media screen and(max-width:576px) {
        top: 0px;
        left: 75px;
        border-radius: 30px;
        width: 67%;
      }

      @media screen and(max-width:400px) {
        top: 24px;
        left: 48px;
        border-radius: 30px;
        width: 67%;
      }

      img {
        width: 100%;
      }
    }
    // .user-name .img-fluid{
    //   margin-bottom: 1px;
    // }
  }
}
.meet-title-center {
  font-weight: 500;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  color: #fdf3f3;
}
.meet-subtitle {
  color: #898989;
  font-size: 24px;
  font-weight: 400;
}
.quantori-link {
  color: #fdf3f3;
}
// .card-wrapper-box {
//   position: relative;
//   .background-card-shape {
//     @media screen and (max-width: 767px) {
//       margin: 20px 0px;
//     }
//   }
//   .person-images {
//     position: absolute;
//     left: 20%;
//     bottom: 85px;
//     right: 0;
//     max-width: 60%;
//     @media screen and (max-width: 1199px) {
//       bottom: 67px;
//     }

//     @media screen and (max-width: 767px) {
//       bottom: 64px;
//       left: 17%;
//       max-width: 52%;
//     }
//   }
//   .button-box {
//     button {
//       position: absolute;
//       bottom: 0;
//       left: 16%;
//       padding: 16px 90px;
//       border: none;
//       background: rgba(251, 166, 39, 0.5);
//       backdrop-filter: blur(15px);
//       border-radius: 195px;
//       font-size: 18px;
//       display: flex;
//       flex-direction: column;
//       align-items: center;

//       @media screen and (max-width: 1199px) {
//         position: absolute;
//         bottom: 0px;
//         left: 16%;
//         padding: 8px 57px;
//       }
//       @media screen and (max-width: 767px) {
//         position: absolute;
//         bottom: 0px;
//         left: 15%;
//         padding: 11px 97px;
//       }
//     }
//     .person-name {
//       color: rgba(7, 7, 7, 0.969);
//     }
//     .post-name {
//       color: rgba(255, 255, 255, 0.969);
//     }
//   }
// }
