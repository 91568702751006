.subscribe-card {
    background: linear-gradient(146.36deg, #fba627 9.29%, #c60e7b 98.11%);
    border: 2px solid rgba(255, 195, 75, 0.51);
    border-radius: 6px;
    padding: 20px;
    margin: 12px 0;
    height: calc(100% - 24px);
    .card-inner {
      background: linear-gradient(
        180deg,
        #f8d590 0%,
        rgba(255, 255, 255, 0) 100%
      );
      backdrop-filter: blur(121px);
      border-radius: 15px;
      padding: 23px 30px;
      .sub-text {
        font-weight: 700;
        font-size: 30px;
        line-height: 44px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .email-input {
        height: 65.55px;
        border-radius: 8px;
        border: 0;
        padding: 19px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #a9a7a7;
        &::placeholder {
          color: #a9a7a7;
          font-size: 18px;
          line-height: 27px;
        }
      }
      .sign-up-btn {
        height: 65.55px;
        border-radius: 8px;
        background: #ffc34b;
        border: 3px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        font-weight: 700;
        font-size: 22px;
        line-height: 33px;
        color: #242122;
        &:hover {
          background: #c60e7b;
          transition: 0.5s;
        }
      }
    }
  }