.blogdetail-wrap {
  .news-main-wrap {
    .news-menu {
      background-image: url(../../Images/newsBackground.png);
      background-size: cover;
      height: 521px;
      @media screen and(max-width:991px) {
        height: 470px;
      }
      @media screen and(max-width:576px) {
        height: 320px;
      }
      .news-title {
        margin: 70px 0;
        top: -248px;
        height: unset;

        @media screen and(max-width:991px) {
          margin: 180px 0px 0px 0px;
        }
        @media screen and(max-width:576px) {
          margin: 160px 0px 0px 0px;
          padding: 0px 16px;
        }
      }
      .aero-button {
        gap: 15px;
        .left-aero-button {
          width: 58px;
          height: 58px;
          background: rgba(198, 14, 123, 0.41);
          opacity: 0.5;
          border-radius: 50%;
          border: none;
          img {
            @media screen and(max-width:576px) {
              width: 20px;
              height: 20px;
            }
          }
          @media screen and(max-width:991px) {
            width: 45px;
            height: 45px;
          }
          @media screen and(max-width:576px) {
            width: 38px;
            height: 38px;
          }
        }
        .title-back-blog {
          font-weight: 300;
          font-size: 30px;
          line-height: 44px;
          text-align: center;
          color: #ffffff;

          a {
            color: #ffffff;
            text-decoration: none;
            &:hover {
              cursor: pointer;
            }
          }
          @media screen and(max-width:991px) {
            font-size: 24px;
          }
        }
      }
    }
    .img-wrap {
      position: relative;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 1;
        object-fit: cover;
      }
    }
  }
  .blog-images {
    background-color: #242122;
    padding: 20px 0px 50px 0px;
    position: relative;
  }
}
