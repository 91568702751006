.navContainer {
  background: linear-gradient(
    90.88deg,
    rgba(250, 166, 36, 0.81) -1.81%,
    rgba(255, 255, 255, 0.1) 40.72%
  );
  z-index: 3;
  border: 1px solid rgba(251, 166, 39, 0.99);
  backdrop-filter: blur(43px);
  border-radius: 20px;
  margin-top: 32px;
  .navbar-brand {
    padding-left: 12px;
  }
  .navbar-collapse {
    .navbar-nav {
      display: flex;
      align-items: center;
      .nav-item {
        padding: 0 16px;
        cursor: pointer;
        &:last-child {
          padding: 0;
          margin-right: 12px;
        }
        &:hover {
          .nav-link {
            position: relative;
            // color: #fff;
            color: #c60e7b;
            &:before {
              position: absolute;
              bottom: -8px;
              width: 50%;
              height: 2px;
              left: 50%;
              transform: translateX(-50%);
              // background: #c60e7b;
              background: #fba627;
              content: "";
            }
          }
        }
        .nav-link {
          padding: 0;
          position: relative;
          color: #fff;
          &.active {
            // color: #fff;
            color: #c60e7b;
            &:before {
              position: absolute;
              bottom: -8px;
              width: 50%;
              height: 2px;
              left: 50%;
              transform: translateX(-50%);
              // background: #c60e7b;
              background: #fba627;
              content: "";
            }
          }
        }
      }
    }
  }
  .leftContainer {
    display: flex;
    align-items: center;
    .logo {
      max-width: 45px;
      margin-right: 14px;
    }
    .logoText {
      color: #fff;
      font-weight: 500;
      font-size: 36px;
      line-height: 59px;
    }
  }
  .loginButton {
    margin-left: 80px;
    background: linear-gradient(109.93deg, #fba627 18.28%, #c60e7b 92.65%);
    border-radius: 6px;
    padding: 0 34px;
    line-height: 40px;
    color: #fff;
    font-weight: 500;
    border: none;
    font-size: 20px;
    line-height: 48px;
    text-decoration: none;
    @media screen and (max-width: 991px) {
      background: #c60e7b;
      padding: 0px 20px;
    }  
  }
  @media screen and (max-width: 991px) {
    .navbar-collapse {
      .navbar-nav {
        .nav-item {
          width: 100%;
          padding: 8px 20px;
          .nav-link {
            &.active {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
    .navbar-toggler {
      margin-right: 12px;
      border-color: #fff;
      color: transparent;
      &:focus {
        border: 1px solid #fba627;
      }
      .menu-icon-bar {
        color: #fff;
        font-weight: 400;
        font-size: 20px;
      }
    }
    .loginButton {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 991px) {
  .navContainer {
    position: absolute;
    width: calc(100% - 30px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }
}
@media screen and (max-width: 567px) {
  .navContainer {
    width: calc(100% - 30px);
  }
}
