.card-wrapper-box {
  position: relative;
}

.background-card-shape {
  @media screen and (max-width: 767px) {
    margin: 20px 0px;
  }
}
.person-images {
  left: 16.5%;
  bottom: 68px;
  right: 0;
  @media screen and (max-width: 1199px) {
    bottom: 67px;
  }
  @media screen and (max-width: 767px) {
    bottom: 64px;
    left: 17%;
  }
  @media screen and(max-width:576px) {
    bottom: 60px;
    left: 20%;
  }
  @media screen and(max-width:400px) {
    bottom: 60px;
    left: 23%;
  }
}
.button-box {
  .board {
    position: absolute;
    bottom: 1px;
    left: 13%;
    width: 294px;
    height: 80px;
    border: none;
    background: rgba(251, 166, 39, 0.5);
    backdrop-filter: blur(15px);
    border-radius: 195px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    padding-top: 10px;
    @media screen and (max-width: 1400px) {
      position: absolute;
      bottom: 0px;
      left: 7%;
    }
    @media screen and (max-width: 1200px) {
      position: absolute;
      bottom: 0px;
      left: 4%;
      width: 257px;
      height: 69px;
      row-gap: 0px;
      padding-top: 7px;
    }
    @media screen and (max-width: 991px) {
      position: absolute;
      bottom: 0px;
      left: 10%;
      width: 257px;
      height: 69px;
      row-gap: 0px;
      padding-top: 7px;
    }
    @media screen and (max-width: 767px) {
      position: absolute;
      bottom: 0px;
      left: 20%;
      width: 294px;
      height: 80px;
    }
    @media screen and (max-width: 576px) {
      position: absolute;
      bottom: 0px;
      left: 20%;
      width: 303px;
      height: 70px;
    }
    @media screen and (max-width: 420px) {
      bottom: 0px;
      left: 9%;
      width: 82%;
      height: 63px;
      row-gap: 0px;
      padding-top: 4px;
    }
  }
  .person-name {
    color: rgba(7, 7, 7, 0.969);
  }
  .post-name {
    color: rgba(255, 255, 255, 0.969);
  }
}
