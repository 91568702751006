.main-wrapper-box {
  height: 100vh;
  flex-direction: column;
  display: flex;
  flex: 1;
  .login-background-wrapper {
    background: url(../../Images/BG-For-Login.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px;
    height: 100%;
    .login-wrapper {
      background: linear-gradient(
        138.59deg,
        rgba(255, 255, 255, 0.4) -0.95%,
        rgba(255, 255, 255, 0.1) 102.21%
      );
      padding: 40px;
      backdrop-filter: blur(30px);
      border-radius: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .altr-logo {
      display: flex;
      justify-content: center;
      max-width: 90px;
      margin: 0 auto;
      img {
        max-width: 100%;
      }
    }
    .identity-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;

        @media screen and (max-width: 1199px) {
          width: 100%;
        }
      }
    }
  }

  // .login-box-imges {
  //   height: 100vh;
  // }
  .alt-login-wrapper {
    padding: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 576px) {
      padding: 24px;
    }
    &::after {
      content: "";
      background: url("../../Images/login-bottom.png") no-repeat center bottom /
        contain;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100px;
      bottom: 0;
    }
    .custom-login-form {
      width: 576px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .login-title {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 59px;
      color: #000000;
      @media screen and (max-width: 576px) {
        font-size: 28px;
        line-height: 30px;
      }
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #615f5f;
      @media screen and (max-width: 576px) {
        font-size: 14px;
        margin: 0;
      }
    }
    .form-login-pages {
      .form-group-box {
        position: relative;
        .form-label {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 15px 0px;
          margin: 0;
          @media screen and (max-width: 576px) {
            font-size: 14px;
            padding: 6px 0px;
          }
        }
        .email-icon {
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          img {
            &:hover {
              cursor: pointer;
            }
          }
        }
        input {
          &::placeholder {
            color: #a9a6a6;
          }
        }
        .form-control {
          height: 63px;
          background: #f5f5f5;
          border: 1px solid #f79e28;
          border-radius: 10px;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          @media screen and (max-width: 991px) {
            height: 48px;
          }
          @media screen and (max-width: 576px) {
            height: 42px;
            font-size: 14px;
          }
        }
      }
      .check-box-title {
        padding: 24px 0px 42px 0px;

        @media screen and (max-width: 991px) {
          padding: 10px 0px 10px 0px;
        }
        .check-box {
          .form-check-input:checked {
            background-color: #c91776;
            border: 0;
          }
          input {
            width: 23px;
            height: 23px;
            @media screen and (max-width: 576px) {
              width: 18px;
              height: 18px;
            }
          }
        }
        .remeber-title {
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #010101;
          @media screen and (max-width: 576px) {
            font-size: 14px;
          }
        }
        .forget {
          margin-bottom: 0;
          a {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #f5982c;
            text-decoration: none;
            @media screen and (max-width: 576px) {
              font-size: 14px;
            }
          }
        }
      }
      .button-wrapper {
        button {
          height: 63px;
          width: 100%;
          border: none;
          background: linear-gradient(103.13deg, #faa624 4.09%, #c91776 55.22%);
          border-radius: 10px;
          font-weight: 700;
          font-size: 22px;
          line-height: 33px;
          color: #ffffff;
          &:hover {
            background: linear-gradient(
              103.13deg,
              hsl(38, 100%, 52%) 4.09%,
              #f7178e 55.22%
            );
            transition: 0.5s;
            cursor: pointer;
          }
          @media screen and (max-width: 991px) {
            height: 48px;
            font-size: 18px;
          }
          @media screen and (max-width: 576px) {
            height: 42px;
            font-size: 14px;
          }
        }
      }
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        @media screen and (max-width: 991px) {
          font-size: 14px;
          margin: 0;
        }
      }
      .button-gray {
        button {
          background: #d9d9d9;
          border-radius: 10px;
          height: 63px;
          border: none;
          width: 100%;
          font-weight: 400;
          font-size: 22px;
          line-height: 33px;
          color: #8e8484;
          @media screen and (max-width: 991px) {
            height: 48px;
            font-size: 18px;
          }
          @media screen and (max-width: 576px) {
            height: 42px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
