.main-wrapper {
  background: rgba(36, 33, 34, 0.969);
  overflow: hidden;
  .header-section {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      background-color: #0000004d;
      position: absolute;
      width: 100%;
      min-height: 200px;
      height: 100%;
      top: -2px;
      transform: rotate(355deg);
      z-index: -1;
      transform: skew(360deg, 349deg);
    }
    height: 500px;
    .banner {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .penta {
        display: none;
      }
      .curve {
        width: 200px;
        height: 200px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .businessmanImage {
        position: absolute;
        right: 0;
        width: 550px;
        height: auto;
      }
    }
  }
  .bannerContent {
    margin-top: 100px;
    .headerMainText {
      font-weight: 500;
      font-size: 44px;
      line-height: 66px;
      color: #fff;
      margin-bottom: 25px;

      @media screen and (max-width: 1199px) {
        font-size: 32px;
        line-height: 48px;
      }
    }
    .headerText {
      font-weight: 200;
      font-size: 32px;
      line-height: 47px;
      color: #fff;
      margin-bottom: 40px;
      a {
        color: rgb(255, 255, 255);
        font-weight: 400;
        text-decoration: none;
        &:hover {
          color: #faa624;
          cursor: pointer;
        }
      }

      @media screen and (max-width: 1199px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }
    .connectButton {
      background: #faa624;
      border-radius: 6px;
      padding: 5px 24px;
      font-weight: 500;
      font-size: 16px;
      z-index: 2;
      text-decoration: none;
      &:hover {
        background: #c60e7b;
        color: #fff;
      }
    }
  }
  .security-wrapper {
    padding-top: 50px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: red;
    }
    .securityHead {
      position: relative;
      width: 100%;
      padding-top: 85px;
      @media screen and (max-width: 576px) {
        padding: 0px 16px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        background: url("../../Images/Polygon-right.svg") no-repeat top center /
          contain;
        height: 60px;
        width: 30px;
        top: -45px;
      }
      &:before {
        content: "";
        position: absolute;
        right: 0;
        width: 45px;
        background: url("../../Images/PolygonLeft.svg") no-repeat top center /
          contain;
        height: 90px;
        top: -40px;
        @media screen and(max-width:576px) {
          display: none;
        }
      }
      h3 {
        font-size: 52px;
        font-weight: 500;
        line-height: 45px;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 1199px) {
          font-size: 42px;
          padding: 0px 10px;
        }
        @media screen and (max-width: 576px) {
          font-size: 18px;
          color: #fba627;
          margin: 0;
        }
      }
    }
  }
  .service-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 100px 0;
    align-items: center;
    @media screen and (max-width: 567px) {
      align-items: center;
      padding: 80px 0px 0px 0px;
    }
    .round {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      @media screen and (max-width: 1199px) {
        width: 140px;
        height: 140px;
      }

      @media screen and (max-width: 991px) {
        width: 140px;
        height: 140px;
      }
      @media screen and (max-width: 767px) {
        width: 160px;
        height: 160px;
      }
      @media screen and (max-width: 567px) {
        width: 140px;
        height: 140px;
      }
      @media screen and (max-width: 375px) {
        width: 114px;
        height: 114px;
      }
      img {
        height: 120px;
        @media screen and (max-width: 1199px) {
          height: 90px;
        }

        @media screen and (max-width: 375px) {
          height: 75px;
        }
      }
      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 92px;
        text-align: center;
        text-transform: uppercase;
        color: #c60e7b;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0;
        @media screen and (max-width: 1199px) {
          font-size: 34px;
        }
        @media screen and (max-width: 991px) {
          font-size: 30px;
        }

        @media screen and (max-width: 767px) {
          font-size: 40px;
        }
        @media screen and (max-width: 576px) {
          font-size: 30px;
        }

        @media screen and (max-width: 375px) {
          font-size: 24px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 42px;
        line-height: 62px;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
        color: #c60e7b;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 1199px) {
          font-size: 34px;
        }
      }
    }
    .hexa {
      .serviceText {
        position: absolute;
        top: 50%;
        transform: translateY(-25%);
        padding: 0 50px;
        @media screen and(max-width:375px) {
          top: 53%;
          padding-top: 10px;
        }
        @media screen and(max-width:576px) {
          top: 53%;
          padding-top: 10px;
        }
        a {
          font-weight: 500;
          font-size: 12px;
          line-height: 33px;
          color: #000;
          text-decoration: none;
          border-bottom: 1px solid #000;
          @media screen and (max-width: 1199px) {
            border-bottom: none;
            line-height: 22px;
          }
        }
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
          @media screen and (max-width: 1199px) {
            font-size: 14px;
          }
          @media screen and (max-width: 767px) {
            font-size: 19px;
          }
          @media screen and (max-width: 567px) {
            font-size: 19px;
          }

          @media screen and(max-width:375px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .manage-wrapper {
    padding: 0 0 100px 0;
    position: relative;
    margin: 56px 0 0 0;
    z-index: 1;
    .images-box {
      @media screen and (max-width: 576px) {
        padding: 5px 16px;
      }
    }

    &::before {
      content: "";
      background-color: #0000004d;
      position: absolute;
      width: 100%;
      min-height: 200px;
      height: 100%;
      transform: rotate(355deg);
      z-index: -1;
      // transform: skew(2deg, 352deg);
      transform: skew(360deg, 349deg);
    }
    @media screen and (max-width: 576px) {
      // padding: 5px 16px;
    }
    .gifWapper {
      max-width: 20%;
      height: auto;
      position: absolute;
      height: auto;
      bottom: 29.8%;
      right: 11.5%;
      @media screen and (max-width: 991px) {
        max-width: 20%;
      }
      @media screen and (max-width: 576px){
        max-width: 19%;
        margin: 0px 10px 0px 0px;
      }
      
      @media screen and (max-width: 400px){
        max-width: 18%;
        margin: 0px 12px 3px 0px;
      }

      img {
        max-width: 100%;
        height: 100%;
        width: 100%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("../../Images/Polygon7.svg") no-repeat top center /
        contain;
      width: 47px;
      height: 70px;
      @media screen and (max-width: 567px) {
        display: none;
      }
    }
    .manage-content {
      padding-left: 50px;
      @media screen and (max-width: 567px) {
        margin-top: 20px;
        padding-left: 0px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 20px;
        padding: 5px 16px;
      }
      h4 {
        font-weight: 500;
        font-size: 28px;
        line-height: 30px;
        color: #fba627;
        margin-bottom: 40px;
        @media screen and(max-width:425px) {
          font-size: 22px;
        }
      }
      .for-section {
        margin-bottom: 30px;
        h5 {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.115em;
          color: #ffffff;
          margin-bottom: 10px;
          span {
            background: linear-gradient(180deg, #f28d34 0%, #d33465 100%);
            border-radius: 6px 0px 0px 6px;
            padding-left: 38px;
            padding-right: 5px;
          }
        }
        p {
          font-weight: 200;
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          letter-spacing: 1.1px;
        }
      }
    }
  }
  .testimonial {
    @media screen and (max-width: 567px) {
      padding: 0px 16px;
    }
    padding-bottom: 6%;
    .head-text {
      position: relative;
      width: 100%;
      padding: 80px 0px 50px 0px;
      &:before {
        content: "";
        position: absolute;
        right: 8%;
        width: 45px;
        background: url("../../Images/Polygon9.svg") no-repeat top center /
          contain;
        height: 46px;
        top: 1px;
      }
      h3 {
        font-size: 38px;
        font-weight: 500;
        line-height: 45px;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 576px) {
          font-size: 28px;
          color: #fba627;
        }
        @media screen and(max-width: 425px) {
          font-size: 22px;
          color: #fba627;
        }
      }
    }
    .testimonial-slide {
      .btnGroup {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 45px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 45px;
          background: url("../../Images/Polygon9.svg") no-repeat top center /
            contain;
          height: 46px;
          top: 30px;
        }
        .btn-connect {
          background: #c60e7b;
          border: 3px solid rgba(255, 255, 255, 0.17);
          border-radius: 10px;
          font-size: 16px;
          border: none;
          padding: 10px 40px;
          color: #fff;
          font-weight: 500;
          transition: 0.5s ease;
          text-decoration: none;
          &:hover {
            background: #faa624;
            color: #242122;
          }
        }
      }
      .slick-slider {
        .slick-arrow { display: none !important;}
      
        .slick-list {
          .slick-track {
            display: flex;
            .slick-slide {
              margin: 10px;
            }
          }
        }
        .slick-dots {
          width: 94%;
        }
      }
      .slick-dots li button:before{
        color: #ffffff !important;
      }
     
      .box {
        background: #fba627;
        border: 1px solid #c60e7b;
        border-radius: 12px;
        padding: 40px 16px 16px;
        height: 350px;
        margin-right: 26px;
        display: flex;
        flex-direction: column;
        flex: 1;
        &:last-child {
          margin-right: 0;
        }
        .link {
          margin-top : 5px;
          border-left: 5px solid#C60E7B;
          padding-left: 10px;
          a {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #fff;
          }
        }
        .carousel-text {
          flex: 1;
          padding-left: 10px;
          margin-top: 15px;
          h5 {
            line-height: 38px;
            @media screen and(max-width:1199px) {
              line-height: 24px;
            }
            @media screen and(max-width:991px) {
              line-height: 22px;
              font-size: 18px;
            }
            @media screen and(max-width:767px) {
              line-height: 16px;
              font-size: 15px;
            }
            @media screen and(max-width:567px) {
              line-height: 39px;
              font-size: 28px;
            }
            @media screen and(max-width:425px) {
              font-size: 23px;
              line-height: 39px;
            }
          }
          h3 {
            @media screen and(max-width:576px) {
              font-size: 31px;
            }
          }
          .need-title {
            font-size: 44px;

            @media screen and(max-width:1199px) {
              font-size: 32px;
            }
            @media screen and(max-width:767px) {
              font-size: 22px;
            }
            @media screen and(max-width:567px) {
              font-size: 60px;
            }
            @media screen and(max-width:425px) {
              font-size: 48px;
            }
          }
        }
      }
    }
  }
  .blog-section {
    background: url("../../Images/blogBg.png") no-repeat top center/cover;
    height: 700px;
    position: relative;
    @media screen and (max-width: 567px) {
      padding: 22px 16px;
    }
    &:before {
      content: "";
      background: url("../../Images/PolygonLeft.svg") no-repeat top
        center/contain;
      position: absolute;
      right: 0;
      height: 100px;
      width: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    .latest-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      position: relative;
      padding-left: 50px;
      @media screen and(max-width:567px) {
        padding-left: 0;
      }
      &:before {
        content: "";
        background: url("../../Images/Polygon11.svg") no-repeat top
          center/contain;
        position: absolute;
        right: 50px;
        height: 100px;
        width: 50px;
        top: 0;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 36px;
        line-height: 71px;
        color: #ffffff;
        @media screen and (max-width: 1199px) {
          font-size: 28px;
        }
        @media screen and(max-width:991px) {
          font-size: 22px;
        }
        @media screen and(max-width:567px) {
          font-size: 18px;
        }
      }
      .btnGroup {
        .btn-post {
          background: #c60e7b;
          border: 3px solid rgba(255, 255, 255, 0.17);
          border-radius: 10px;
          font-size: 16px;
          border: none;
          padding: 10px 40px;
          color: #fff;
          font-weight: 500;
          transition: 0.5s ease;
          text-decoration: none;
          &:hover {
            background: #faa624;
            color: #242122;
          }
        }
      }
    }
    .card-blog {
      background: #fff;
      border-radius: 6px;
      padding: 20px;
      .blog-btn-wrapper {
        position: relative;
        border-radius: 6px;
        overflow: hidden;

        button {
          background: #c60e7b;
          font-weight: 600;
          font-size: 35px;
          line-height: 52px;
          letter-spacing: 0.3em;
          padding: 24px;
          color: #edecec;
          position: absolute;
          border: none;
          border-radius: 4px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.5s ease;
          &:hover {
            background: #faa624;
            color: #242122;
          }
        }
      }
      h6 {
        margin-top: 20px;
      }
      .text-pharagraph {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        color: #47484a;
      }
      .text-sub {
        font-style: italic;
      }
      .btn-wrap {
        margin-top: 8px;
        border-top: 1px solid rgba(255, 195, 75, 0.37);
        padding: 16px 0 0;
        .btn-learn {
          background: #faa624;
          border: 3px solid rgba(255, 255, 255, 0.17);
          border-radius: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #242122;
          padding: 5px 24px;
          text-decoration: none;
        }
      }
      .date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #c60e7b;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #71788a;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .header-section {
      // height: auto;
      &:before {
        content: "";
        background: url("../../Images/Polygon11.svg") no-repeat top
          center/contain;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        right: 0;
        bottom: 100px;
      }
      .banner {
        position: relative;
        .curve {
          position: absolute;
          top: 0;
          left: 0;
        }
        .penta {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 90px;
          height: 120px;
        }

        .businessmanImage {
          display: none;
        }
      }
      .mobile-banner {
        margin-top: -100px;
        position: absolute;
        left: 0;
        width: 260px;
      }
      .bannerContent {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .headerMainText {
          text-align: center;
          font-weight: 200;
          font-size: 18px;
          line-height: 27px;
          color: #fba627;
        }
        .headerText {
          text-align: center;
          font-weight: 200;
          font-size: 15px;
          padding: 0 24px;
          line-height: 22px;
        }
      }
    }
  }
}
