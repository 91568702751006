.model-box-images {
  padding: 20px 0px;
  img {
    width: 100%;
    box-shadow: 4px 4px 7px 4px #e1e1e1;
    border-radius: 25px;
    object-fit: contain;
    height: 350px;
  }
}
.title-box {
  font-size: 24px;
  color: #fba627;
  font-weight: 600;
  margin: 0;
  padding: 10px 0px;
}
.pargraph-model {
  font-size: 16px;
  color: #141414c2;
  font-weight: 400;
}
.title-wrap {
  .title-published {
    color: #878787;
    font-size: 16px;
    font-weight: 600;
  }
  .date {
    color: #2a2a2a;
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
  }
}
.owner-name {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0px;
}
