.main-wraper {
  background: rgba(36, 33, 34, 0.969);
  height: 100vh;
}
.error-template {
  padding: 40px 15px;
  text-align: center;
  h1,
  h2 {
    color: white;
  }
}
.error-details {
  color: white;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
